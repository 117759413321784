<template>
  <div>
    <div v-if="!pageIndexApiDataIsLoading">
      <company-licence-component/>

      <div class="row">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5" to="/monitoring"><i class="fa fa-tv fa-fw fa-lg"></i></router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/sales"><i class="fa fa-chart-bar fa-chart-bar fa-lg"></i> Vente &amp; Facturation</router-link>
          <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/stock"><i class="fa fa-cube fa-fw fa-lg text-orange"></i> Stock &amp; Inventaires</router-link>
        </div>
      </div>


      <!--<h1 class="page-header">Centre de controle  <small class="lead">.</small></h1>-->
      <hr class="bg-grey-lighter"/>

      <div class="row m-b-5">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5" to="/monitoring/stock"><i class="fa fa-chart-bar fa-fw fa-lg"></i>
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/stock/stock-periodic-report-per-warehouse"><i
              class="fa fa-calendar fa-fw fa-lg"></i> Suivi Stock &amp; Inventaires
          </router-link>
          <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/stock/stock-orders"><i
              class="fa fa-exchange-alt fa-fw fa-lg text-orange"></i> Entrées/Sorties Libres
          </router-link>
          <router-link class="btn btn-white m-r-5" to="/monitoring/stock/stock-orders-debit"><i
              class="fa fa-exclamation-triangle fa-fw fa-lg"></i> Sorties Spéciales
          </router-link>
        </div>
      </div>

      <div class="row m-b-5">
        <div class="col-md-12 m-b-0">
          <router-link class="btn btn-white m-r-5 text-orange" to="/monitoring/stock/stock-orders"><i
              class="fa fa-truck fa-fw fa-lg text-orange"></i> Par Emplacement
          </router-link>
        </div>
      </div>

      <div v-if="pageIndexApiData != null">
        <div class="row text-right m-b-5">
          <div class="col-12">
            <a href="#" class="btn btn-dark m-l-5" @click="$bvModal.show('formModal')"><i
                class="fa fa-filter fa-fw fa-lg"></i></a>
          </div>
        </div>
        <div class="row">
          <div class="col-12" v-if="reportApiData != null">
            <div class="row">
              <div class="col-12">
                <div class="invoice">
                  <div class="invoice-company">
                                            <span class="pull-right hidden-print">
                                                <a :href="reportApiData.document_url_pdf" target="_blank"
                                                   class="btn btn-sm btn-white m-b-10 mr-2"
                                                   v-if="reportApiData.document_url_pdf != null"><i
                                                    class="fa fa-file-pdf text-danger fa-fw fa-lg"></i> PDF</a>
                                            </span>
                    <h1>{{ reportApiData.warehouse_name }}<br/>
                      <small class="lead">Suivi des mouvements d'entrée/sortie libres du {{ reportApiData.date_from }} au
                        {{ reportApiData.date_to }}</small>
                    </h1>
                  </div>

                  <div class="invoice-content">
                    <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Entrées/Sorties en Quantité
                    </h3>
                    <b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="nav nav-tabs">
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Entrées Libres</span>
                          <span class="d-sm-block d-none">Entrées Libres <span
                              class="badge badge-warning">{{ reportApiData.table_view_products_stock_credit_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>REF ARTICLE</th>
                              <th>DESIGNATION</th>
                              <th class="text-center">QUANTITE</th>
                              <th class="text-right">MOTIF</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in reportApiData.table_view_products_stock_credit"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td><b>{{ item.erp_code }}</b></td>
                              <td><b>{{ item.name }}</b></td>
                              <td class="text-center"><b>{{ item.total_quantity|formatDecimalNumber }}</b></td>
                              <td class="text-right">{{ item.group_name }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Ajustements Entants</span>
                          <span class="d-sm-block d-none">Ajustements Entants <span
                              class="badge badge-warning">{{ reportApiData.table_view_products_stock_adjust_in_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>REF ARTICLE</th>
                              <th>DESIGNATION</th>
                              <th class="text-center">QUANTITE</th>
                              <th class="text-right">MOTIF</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in reportApiData.table_view_products_stock_adjust_in"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td><b>{{ item.erp_code }}</b></td>
                              <td><b>{{ item.name }}</b></td>
                              <td class="text-center"><b>{{ item.total_quantity|formatDecimalNumber }}</b></td>
                              <td class="text-right">{{ item.group_name }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Sorties Libres</span>
                          <span class="d-sm-block d-none">Sorties Libres  <span
                              class="badge badge-warning">{{ reportApiData.table_view_products_stock_debit_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>REF ARTICLE</th>
                              <th>DESIGNATION</th>
                              <th class="text-center">QUANTITE</th>
                              <th class="text-right">MOTIF</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in reportApiData.table_view_products_stock_debit"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td><b>{{ item.erp_code }}</b></td>
                              <td><b>{{ item.name }}</b></td>
                              <td class="text-center"><b>{{ item.total_quantity|formatDecimalNumber }}</b></td>
                              <td class="text-right">{{ item.group_name }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Ajustements Sortants</span>
                          <span class="d-sm-block d-none">Ajustements Sortants <span
                              class="badge badge-warning">{{ reportApiData.table_view_products_stock_adjust_out_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-striped m-b-0">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>REF ARTICLE</th>
                              <th>DESIGNATION</th>
                              <th class="text-center">QUANTITE</th>
                              <th class="text-right">MOTIF</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in reportApiData.table_view_products_stock_adjust_out"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td><b>{{ item.erp_code }}</b></td>
                              <td><b>{{ item.name }}</b></td>
                              <td class="text-center"><b>{{ item.total_quantity|formatDecimalNumber }}</b></td>
                              <td class="text-right">{{ item.group_name }}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                    </b-tabs>
                    <h3 class="m-t-10"><i class="fa fa-arrow-circle-right text-orange"></i> Documents d'Entrées/Sorties
                    </h3>
                    <b-tabs nav-class="nav-tabs-inverse" nav-wrapper-class="nav nav-tabs">
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Entrées Libres</span>
                          <span class="d-sm-block d-none">Entrées Libres <span
                              class="badge badge-warning">{{ reportApiData.stock_order_documents_credit_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-invoice">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th class="text-left">DOCUMENT</th>
                              <th class="text-left">DATE</th>
                              <th class="text-left">TYPE</th>
                              <th class="text-left">FAMILLE</th>
                              <th class="text-left">INITIÉ PAR</th>
                              <th class="text-left">VALIDÉ PAR</th>
                              <th class="text-right">ARTICLES</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(related_stock_order,index) in reportApiData.stock_order_documents_credit"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td class="text-left"><b><a href="javascript:;"
                                                          @click="onStockOrderClick(related_stock_order.id)">{{ related_stock_order.server_side_code }}</a></b>
                              </td>
                              <td class="text-left">{{ related_stock_order.formatted_creation_date }}</td>
                              <td class="text-left">{{ related_stock_order.type_name }}</td>
                              <td class="text-left">{{ related_stock_order.group_name }}</td>
                              <td class="text-left">{{ related_stock_order.seller_name }}</td>
                              <td class="text-left">{{ related_stock_order.approved_by_name }}</td>
                              <td class="text-right"><b>{{ related_stock_order.items_count|formatNumber }}</b>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Ajustements Entants</span>
                          <span class="d-sm-block d-none">Ajustements Entants <span
                              class="badge badge-warning">{{ reportApiData.stock_order_documents_stock_adjust_in_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-invoice">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th class="text-left">DOCUMENT</th>
                              <th class="text-left">DATE</th>
                              <th class="text-left">TYPE</th>
                              <th class="text-left">FAMILLE</th>
                              <th class="text-left">INITIÉ PAR</th>
                              <th class="text-left">VALIDÉ PAR</th>
                              <th class="text-right">ARTICLES</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(related_stock_order,index) in reportApiData.stock_order_documents_stock_adjust_in"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td class="text-left"><b><a href="javascript:;"
                                                          @click="onStockOrderClick(related_stock_order.id)">{{ related_stock_order.server_side_code }}</a></b>
                              </td>
                              <td class="text-left">{{ related_stock_order.formatted_creation_date }}</td>
                              <td class="text-left">{{ related_stock_order.type_name }}</td>
                              <td class="text-left">{{ related_stock_order.group_name }}</td>
                              <td class="text-left">{{ related_stock_order.seller_name }}</td>
                              <td class="text-left">{{ related_stock_order.approved_by_name }}</td>
                              <td class="text-right"><b>{{ related_stock_order.items_count|formatNumber }}</b>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Sorties Libres</span>
                          <span class="d-sm-block d-none">Sorties Libres <span
                              class="badge badge-warning">{{ reportApiData.stock_order_documents_debit_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-invoice">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th class="text-left">DOCUMENT</th>
                              <th class="text-left">DATE</th>
                              <th class="text-left">TYPE</th>
                              <th class="text-left">FAMILLE</th>
                              <th class="text-left">INITIÉ PAR</th>
                              <th class="text-left">VALIDÉ PAR</th>
                              <th class="text-right">ARTICLES</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(related_stock_order,index) in reportApiData.stock_order_documents_debit"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td class="text-left"><b><a href="javascript:;"
                                                          @click="onStockOrderClick(related_stock_order.id)">{{ related_stock_order.server_side_code }}</a></b>
                              </td>
                              <td class="text-left">{{ related_stock_order.formatted_creation_date }}</td>
                              <td class="text-left">{{ related_stock_order.type_name }}</td>
                              <td class="text-left">{{ related_stock_order.group_name }}</td>
                              <td class="text-left">{{ related_stock_order.seller_name }}</td>
                              <td class="text-left">{{ related_stock_order.approved_by_name }}</td>
                              <td class="text-right"><b>{{ related_stock_order.items_count|formatNumber }}</b>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                      <b-tab>
                        <template slot="title">
                          <span class="d-sm-none">Ajustements Sortants</span>
                          <span class="d-sm-block d-none">Ajustements Sortants  <span
                              class="badge badge-warning">{{ reportApiData.stock_order_documents_stock_adjust_out_count }}</span></span>
                        </template>
                        <div class="table-responsive">
                          <table class="table table-invoice">
                            <thead>
                            <tr>
                              <th>#</th>
                              <th class="text-left">DOCUMENT</th>
                              <th class="text-left">DATE</th>
                              <th class="text-left">TYPE</th>
                              <th class="text-left">FAMILLE</th>
                              <th class="text-left">INITIÉ PAR</th>
                              <th class="text-left">VALIDÉ PAR</th>
                              <th class="text-right">ARTICLES</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(related_stock_order,index) in reportApiData.stock_order_documents_stock_adjust_out"
                                :key="index">
                              <td><b>{{ index + 1 }}</b></td>
                              <td class="text-left"><b><a href="javascript:;"
                                                          @click="onStockOrderClick(related_stock_order.id)">{{ related_stock_order.server_side_code }}</a></b>
                              </td>
                              <td class="text-left">{{ related_stock_order.formatted_creation_date }}</td>
                              <td class="text-left">{{ related_stock_order.type_name }}</td>
                              <td class="text-left">{{ related_stock_order.group_name }}</td>
                              <td class="text-left">{{ related_stock_order.seller_name }}</td>
                              <td class="text-left">{{ related_stock_order.approved_by_name }}</td>
                              <td class="text-right"><b>{{ related_stock_order.items_count|formatNumber }}</b>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                  <div class="invoice-footer">
                    <p class="text-center m-b-5 f-w-600">
                      MERCI DE VOTRE FIDELITE
                    </p>
                    <p class="text-center">
                      <span class="m-r-10"><i
                          class="fa fa-fw fa-lg fa-globe"></i> {{ reportApiData.company_website }}</span>
                      <span class="m-r-10"><i
                          class="fa fa-fw fa-lg fa-phone-volume"></i> T:{{ reportApiData.company_phone }}</span>
                      <span class="m-r-10"><i class="fa fa-fw fa-lg fa-envelope"></i> {{ reportApiData.company_email }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-else>
            <div class="panel">
              <div class="panel-body">
                <div class="invoice">
                  <div class="invoice-company">

                    <h3 class="page-header">Suivi des mouvements d'entrée/sortie libres.<br/>
                      <small class="lead">Dans cette section, vous pouvez suivre les mouvements libres d'entrée/sortie
                        de stock. Il s'agit des bons d'entrée ou de sortie initiés librement par des agents sans que
                        cela ne soit justifié par une facture de vente.
                      </small></h3>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Dialogs -->
        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="formModal"
            title="Modal Title"
            size="md"
            @show="()=>{
              this.reportApiDataErrorMessage = null;
            }"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('formModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <h1 class="page-header">Choisir un emplacement <small class="lead"></small></h1>
          <div class="row" v-if="reportApiDataIsLoading">
            <div class="col-12 m-b-10">
              <small class="lead text-warning"><i class="fas fa-spinner fa-pulse"></i> Chargement en
                cours...</small><br/>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12" v-if="reportApiDataErrorMessage != null">
              <div class="alert alert-danger">
                {{ reportApiDataErrorMessage }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <form
                  @submit.prevent="getReportData">
                <div class="form-group row">
                  <div class="col-12">
                    <label>Choisir un emplacement</label>
                    <v-select label="name" :options="pageIndexApiData.warehouses"
                              :reduce="item => item.id"
                              v-model="form.warehouse_id"/>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <label>Définir la période</label>
                    <date-range-picker ref="picker" class="btn btn-white btn-block text-right"
                                       opens="right"
                                       :ranges="dateRanges"
                                       :locale-data="{
                                                      format: 'dd-mm-yyyy',
                                                      separator: ' - ',
                                                      applyLabel: 'Appliquer',
                                                      cancelLabel: 'Annuler',
                                                      weekLabel: 'S',
                                                      customRangeLabel: 'Plage Perso.',
                                                      daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                                                      monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                                                      firstDay: 1
                                                    }"
                                       :singleDatePicker="false"
                                       :timePicker="false"
                                       :timePicker24Hour="true"
                                       :showWeekNumbers="false"
                                       :showDropdowns="false"
                                       :autoApply="true"
                                       v-model="form.date_range"
                                       @update="()=>{}"
                                       :linkedCalendars="false">
                      <template v-slot:input="picker" class="text-right">
                        {{ picker.startDate | formatJSDate }} - {{ picker.endDate | formatJSDate }}
                        <i class="fa fa-calendar fa-fw fa-lg text-dark"></i>
                      </template>
                    </date-range-picker>
                  </div>
                </div>
                <!--
                <div class="form-group row">
                  <div class="col-12">
                    <div class="checkbox checkbox-css checkbox-inline">
                      <input type="checkbox" id="pdf" v-model="form.to_pdf"/>
                      <label for="pdf"><i class="fas fa-download text-warning"></i>
                        Télécharger au format PDF</label>
                    </div>
                  </div>
                </div>
                -->
                <div class="form-group row">
                  <div class="col-12 text-right">
                    <button :disabled="reportApiDataIsLoading" type="submit" class="btn btn-dark">
                      <i class="fas fa-spinner fa-spin" v-if="reportApiDataIsLoading"></i>
                      <i class="fas fa-filter" v-else></i> Filtrer
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>
        <b-modal
            centered
            hide-header
            hide-footer
            no-close-on-esc
            no-close-on-backdrop
            hide-header-close
            id="stockOrderModal"
            title="Modal Title"
            size="xl"
            @show="()=>{}"
            @hidden="()=>{}">

          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-danger" @click="$bvModal.hide('stockOrderModal')"><i
                  class="fa fa-times fa-fw fa-lg"></i></a>
            </p>
          </div>

          <stock-order-component :itemId="selectedStockOrderId"/>

          <!--
          <div class="row ">
            <p class="col-12 text-right">
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-comment fa-fw fa-lg"></i></a>
              <a href="javascript:;" class="btn btn-white m-r-5" @click="()=>{}"><i class="fa fa-cogs fa-fw fa-lg"></i></a>
            </p>
          </div>
          -->
        </b-modal>
      </div>
    </div>
    <div class="panel-loader" v-else><span class="spinner-small"></span></div>
  </div>
</template>


<script>
import pageApi from '../../store/api/monitoring/warehouse-stock-orders-page-api'
import LocalStorage from "../../store/local-storage";
import CompanyLicenceComponent from '../../components/app/base/CompanyLicenceComponent.vue'
import StockOrderComponent from "@/components/app/StockOrderComponent";

export default {
  name: 'warehouse-stock-orders-page-component',
  components: {
    StockOrderComponent,
    CompanyLicenceComponent,
  },
  props: {},
  watch: {},
  computed: {},
  methods: {
    //Initialization Methods
    loadPageScripts() {
    },

    formatDateInput (value) {
      if (value) {
        return window.moment(String(value)).format(window.appDefaultDisplayDateTimeFormat)
      }
      return null;
    },


    onStockOrderClick(id) {
      this.selectedStockOrderId = id;
      this.$bvModal.show('stockOrderModal');
    },

    //Other Methods
    async getPageIndexApiData() {
      this.pageIndexApiDataIsLoading = true;
      this.pageIndexApiDataErrorMessage = null;
      try {
        const response = await pageApi.getIndexData();
        //console.log(response);
        this.pageIndexApiData = response.data;
        this.pageIndexApiDataIsLoading = false;
        this.pageIndexApiDataErrorMessage = null;
      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 419) {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            LocalStorage.clearSessionData();
            this.$router.push('login');
          } else {
            this.pageIndexApiDataIsLoading = false;
            this.pageIndexApiDataErrorMessage = error.response.data.message;
            //
            this.$swal({
              icon: 'error',//'info','success','warning','error','question',
              toast: true,
              position: 'bottom-end',
              timer: 3000,
              showConfirmButton: false,
              title: 'HTTP ' + error.response.status,
              text: error.response.data.message,
            })
          }
        } else {
          this.pageIndexApiDataIsLoading = false;
          this.pageIndexApiDataErrorMessage = error.message;
          //
          this.$swal({
            icon: 'error',//'info','success','warning','error','question',
            toast: true,
            position: 'bottom-end',
            timer: 3000,
            showConfirmButton: false,
            //title: error.message
            text: error.message,
          })
        }
      }
    },

    async getReportData() {
      this.reportApiDataIsLoading = true;
      this.reportApiDataErrorMessage = null;
      this.reportApiDataValidationErrors = [];
      try {
        const response = await pageApi.getReportData({
          warehouse_id: this.form.warehouse_id,
          date_from: this.formatDateInput(this.form.date_range.startDate),
          date_to: this.formatDateInput(this.form.date_range.endDate),
          to_pdf: this.form.to_pdf
        });
        //console.log(response);
        this.reportApiData = response.data;
        this.reportApiDataIsLoading = false;
        this.reportApiDataErrorMessage = null;
        this.reportApiDataValidationErrors = [];

        this.$bvModal.hide('formModal');

      } catch (error) {
        if (error.response) {
          //Server responded with a status code that is NOT in the HTTP 200 range
          //console.log("Headers", error.response.headers);
          //console.log("Status Code", error.response.status);
          //console.log("Response Data", error.response.data);
          this.reportApiDataIsLoading = false;
          this.reportApiDataErrorMessage = error.response.data.message;
          if (error.response.status === 422) {
            this.reportApiDataValidationErrors = error.response.data.errors;
          } else {
            this.reportApiDataValidationErrors = [];
          }
        } else {
          this.reportApiDataIsLoading = false;
          this.reportApiDataErrorMessage = error.message;
          this.reportApiDataValidationErrors = [];
        }
      }
    },
  },
  data: function () {

    let today = new Date()
    today.setHours(0, 0, 0, 0)
    let todayEnd = new Date()
    todayEnd.setHours(11, 59, 59, 999);
    let yesterdayStart = new Date()
    yesterdayStart.setDate(today.getDate() - 1)
    yesterdayStart.setHours(0, 0, 0, 0);
    let yesterdayEnd = new Date()
    yesterdayEnd.setDate(today.getDate() - 1)
    yesterdayEnd.setHours(11, 59, 59, 999);
    let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 11, 59, 59, 999);


    return {
      appName: window.appConfig.appName,
      appCurrency: window.appConfig.appCurrency,
      intervalId01: 0,

      selectedStockOrderId: null,

      form: {
        warehouse_id: null,
        date_range: {
          startDate: null,
          endDate: null,
        },
        to_pdf: false,
      },

      dateRanges: {
        "Aujourd'hui": [today, todayEnd],
        "Hier": [yesterdayStart, yesterdayEnd],
        "Mois en cours": [thisMonthStart, thisMonthEnd],
        "Mois dernier": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 11, 59, 59, 999)
        ],
        "Année en cours": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 11, 59, 59, 999)
        ],
        "Année dernière": [
          new Date(today.getFullYear() - 1, 0, 1),
          new Date(today.getFullYear() - 1, 11, 31, 11, 59, 59, 999)
        ],
      },

      //API Data
      //
      pageIndexApiData: null,
      pageIndexApiDataIsLoading: false,
      pageIndexApiDataErrorMessage: null,

      reportApiData: null,
      reportApiDataIsLoading: false,
      reportApiDataErrorMessage: null,
      reportApiDataValidationErrors: [],
    };
  },
  created: function () {
    this.getPageIndexApiData();
  },
  mounted: function () {
    this.loadPageScripts();
  },
  beforeDestroy: function () {
  },
}
</script>
